export const config = {
    RPC_URL: 'https://rpc.agoric.nodestake.top',
    REST_URL: 'https://api.agoric.nodestake.top',
    EXPLORER_URL: 'https://agoric.bigdipper.live',
    STAKING_URL: 'https://agoric.omniflix.co/stake',
    NETWORK_NAME: 'Agoric',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'agoric-3',
    CHAIN_NAME: 'Agoric',
    COIN_DENOM: 'BLD',
    COIN_MINIMAL_DENOM: 'ubld',
    COIN_DECIMALS: 6,
    PREFIX: 'agoric',
    COIN_TYPE: 118,
    COSMOSTAION: null,
    COINGECKO_ID: '-',
    GAS_PRICE_STEP_LOW: 0.0025,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
